import React, { useState } from "react";

import { lighten, makeStyles } from "@material-ui/core/styles";
import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderCenterLinks from "components/Header/HeaderCenterLinks.js";

import TextField from "@material-ui/core/TextField";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// import fetch from 'node-fetch';

// import {
//   GoogleMap,
//   useJsApiLoader,
//   Marker,
//   InfoWindow,
// } from "@react-google-maps/api";

import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 650,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    maxHeight: 440,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  // centerPadding: "100px",
  // centerMode: true,
};

// const useStyles = makeStyles(styles);

export default function EnhancedTable() {
  const [Name, setName] = useState("");
  const [Surname, setSurname] = useState("");
  const [Email, setEmail] = useState("");
  const [Telephone, setTelephone] = useState("");
  const [Message, setMessage] = useState("");
  const [IAcceptCheckpoint, setIAcceptCheckpoint] = useState(false);
  const [TheError, setTheError] = useState(false);

  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: "AIzaSyDlMXr0_6MtvAt6LgCIwrqXpYppAWNmnxY",
  // });

  const classes = useStyles();

  // const [selected, setSelected] = React.useState([]);

  async function sendEmail() {
    const the_to_email = "neophytos.lambrou@printaform.com.cy";
    const the_subject = "Contact Us form Email";

    const the_name = Name+" "+Surname;
    // const the_surname = Surname;
    const the_from_email = Email;
    const the_telephone = Telephone;
    const the_message = Message;

    // const the_final_message =
      // "\n\nName: " +
      // the_name +
      // "\nSurname: " +
      // the_surname +
      // "\n\nTelephone: " +
      // the_telephone +
      // "\n\nMessage Text: " +
      // the_message;

    let response = "";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        toemail: the_to_email,
        name: the_name,
        fromemail: the_from_email,
        subject: the_subject,
        telephone:the_telephone,
        message: the_message,
      }),
    };

    try {
      response = await fetch(
        "https://email-service.thetensortech.com/api/send-email/",
        requestOptions
      );
    } catch (error) {
      console.log(error);
      alert("Error in sending information");
      return;
    }
    if (response.status !== 200) {
      console.log(response);
      alert("Invalid Input");
      return;
    }
    alert("Email has been sent!");
    clearValues();
  }

  function clearValues() {
    setName("");
    setSurname("");
    setEmail("");
    setTelephone("");
    setMessage("");
    setIAcceptCheckpoint(false);
    setTheError(false);
  }

  return (
    <div className={classes.root}>
      <Header
        // <Logo_tmp
        //   onClick={() => {
        //     window.location.href = "/";
        //   }}
        // />

        rightLinks={<HeaderLinks />}
        centerLinks={<HeaderCenterLinks />}
        fixed
        color="white"
        // changeColorOnScroll={{
        //   height: 400,
        //   color: "white",
        // }}
        // {...rest}
      />

      <Paper className={classes.paper}>
        <div
          style={{
            height: 75,
          }}
        ></div>
        <GridContainer justify="center">
          <GridItem
            xs={10}
            sm={10}
            md={6}
            style={{
              // display: "inline-block",
              "text-align": "left",
              backgroundColor: "#f4f4f4",
              paddingLeft: "3%",
              paddingRight: "3%",
              paddingTop: "50px",
            }}
          >
            <h3>
              <b>Είμαστε εδώ για να σας βοηθήσουμε </b>
            </h3>

            <p>Συμπληρώστε την φόρμα η καλέστε (+357)22 461 144</p>

            <GridContainer style={{ "align-items": "center" }}>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  style={{ marginBottom: "30px" }}
                  required
                  error={TheError}
                  fullWidth
                  value={Name}
                  label="'Ονομα"
                  id="fullWidth"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  style={{ marginBottom: "30px" }}
                  required
                  error={TheError}
                  fullWidth
                  value={Surname}
                  label="Επίθετο"
                  id="fullWidth"
                  onChange={(e) => {
                    setSurname(e.target.value);
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                style={{ marginBottom: "30px" }}
                required
                error={TheError}
                fullWidth
                value={Email}
                label="Ηλεκτρονικό Ταχυδρομείο"
                id="fullWidth"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                style={{ marginBottom: "30px" }}
                required
                error={TheError}
                fullWidth
                value={Telephone}
                label="Τηλέφωνο"
                id="fullWidth"
                onChange={(e) => {
                  setTelephone(e.target.value);
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                style={{ marginBottom: "30px" }}
                required
                error={TheError}
                multiline
                rows={6}
                fullWidth
                value={Message}
                label="Μήνυμα"
                id="fullWidth"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <FormGroup style={{ paddingBottom: "10px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={IAcceptCheckpoint}
                      onChange={(e) => {
                        setIAcceptCheckpoint(e.target.checked);
                      }}
                    />
                  }
                  label="Συμφωνώ με τους όρους και τις Πολιτικές της Ιστοσελίδας"
                />
              </FormGroup>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#f2bb4c",
                  "border-radius": "25px",
                  "text-transform": "none",
                  marginBottom: "50px",
                  paddingLeft: "17px",
                  paddingRight: "17px",
                }}
                onClick={() => {
                  if (IAcceptCheckpoint) {
                    if (
                      Name === "" ||
                      Surname === "" ||
                      Email === "" ||
                      Telephone === "" ||
                      Message === "" 
                      ){
                        setTheError(true)
                        alert("Συμπληρώστε όλα τα πεδία με αστεράκι για να είναι έγκυρη η φόρμα επικοινωνίας!")
                      }
                      else{
                        sendEmail();
                      }
                  }
                  else{
                    alert("Πρέπει να συμφωνήσετε με τους όρους και τις Πολιτικές της Ιστοσελίδας")
                  }
                }}
              >
                Submit
              </Button>
            </GridItem>
            {/* 99573778 */}
          </GridItem>
          <GridItem
            xs={10}
            sm={10}
            md={6}
            style={{
              display: "inline-block",
              "text-align": "left",
              paddingTop: "50px",
              paddingLeft: "3%",
              paddingRight: "3%",
            }}
          >
            <h3 style={{ marginBottom: "20px" }}>
              <b>Printaform LTD Πληροφορίες</b>
            </h3>

            <p>
              ΔΙΕΥΘΥΝΣΗ
              <br></br>Kτήριο Printform Ltd
              <br></br>Λεωφ Λεμεσού 347
              <br></br>2571 ΒΙ.ΠΕ Νήσου
              <br></br>ΤΘ 25396
              <br></br>1309 Λευκωσία Κύπρος
            </p>
            <p>
              EMAIL<br></br>
              info@printaform.com.cy
            </p>

            <p>
              ΤΗΛΕΦΩΝΟ<br></br>
              (+357)22 461 144
            </p>

            <p>
              ΦΑΞ<br></br>
              (+357)22 347 215
            </p>
            <Button
              style={{
                color: "white",
                backgroundColor: "#f2bb4c",
                "border-radius": "25px",
                "text-transform": "none",
                marginTop: "20px",
                marginBottom: "20px",
                paddingLeft: "17px",
                paddingRight: "17px",
              }}
              href="https://goo.gl/maps/SKCdgY9DPb9yswpz8"
              target="_blank"
            >
              View on Google maps
            </Button>
          </GridItem>
        </GridContainer>
      </Paper>
      <Footer />
    </div>
  );
}
